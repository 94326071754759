import React from "react";

import Socials from "../Socials/Socials";
import Copyright from "../Copyright/Copyright";

import profile from "../../media/logos/profile.png";
import { HOME_PAGE_SECTIONS } from "../../utils/navigation";

const Footer = (): React.ReactElement => (
  <div className="footer" ref={HOME_PAGE_SECTIONS.contact.ref}>
    <h1 className="highlight">CONTACT US</h1>
    <p className="opportunities">
      While we are currently focusing on other endeavors, we are always open to
      making new connections, receiving constructive feedback, and learning
      about potential opportunities to collaborate!
    </p>
    <p>
      <span className="highlight">skating@animeonice.com</span>
    </p>
    <Socials />
    <img src={profile} alt="Anime on Ice profile logo" />
    <Copyright />
    <p className="credits">
      Background snow texture downloaded from{" "}
      <a
        className="credits-snow"
        href="https://www.transparenttextures.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Transparent Textures
      </a>
    </p>
    <p className="credits">
      Background ice with skate marks texture created from{" "}
      <span className="no-wrap">
        photograph by{" "}
        <a
          className="credits-snow"
          href="https://www.kylieying.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Kylie Ying
        </a>
      </span>
    </p>
  </div>
);

export default Footer;
