import React from "react";

import { ProgramSpec } from "../../utils/programs";

interface Props {
  program: ProgramSpec;
}

const Thumbnail = ({ program }: Props): React.ReactElement => (
  <div className="thumbnail" key={program.name}>
    <img className="image" src={program.thumbnail} alt={program.name} />
  </div>
);

export default Thumbnail;
