import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleDown } from "@fortawesome/free-solid-svg-icons";

import Logo from "../../Logo/Logo";

import { HOME_PAGE_SECTIONS, scrollTo } from "../../../utils/navigation";

const GalleryLogo = (): React.ReactElement => {
  return (
    <a
      className="gallery-logo"
      onClick={scrollTo(HOME_PAGE_SECTIONS.about.ref)}
    >
      <Logo />
      <p className="down-arrow-container">
        <FontAwesomeIcon icon={faChevronCircleDown} />
      </p>
    </a>
  );
};

export default GalleryLogo;
