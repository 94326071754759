import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPen } from "@fortawesome/free-solid-svg-icons";
import { faCalendarCheck } from "@fortawesome/free-regular-svg-icons";

import { WikiSpec } from "../../utils/navigation";
import { ProgramSpec } from "../../utils/programs";

interface Props {
  program: ProgramSpec;
  wiki: WikiSpec;
}

const WikiPublished = ({ program, wiki }: Props): React.ReactElement => (
  <div className="wiki-published">
    <h2>
      <i>
        Behind-the-scenes of <span className="no-wrap">~{program.name}~</span>
      </i>
    </h2>
    <p>
      <FontAwesomeIcon icon={faUserPen} /> Written by {wiki.author}
    </p>
    <p>
      <FontAwesomeIcon icon={faCalendarCheck} /> Published in {wiki.date}
    </p>
  </div>
);

export default WikiPublished;
