import React from "react";
import { Helmet } from "react-helmet";
import classnames from "classnames";

import WikiHeader from "./WikiHeader";
import WikiMetadata from "./WikiMetadata";
import WikiIntro from "./WikiIntro";
import WikiPublished from "./WikiPublished";
import WikiContent from "./WikiContent";
import WikiNav from "./WikiNav";
import WikiFooter from "./WikiFooter";

import { WIKIS, scrollTo } from "../../utils/navigation";
import { ProgramId, PROGRAMS } from "../../utils/programs";

interface Props {
  programId: ProgramId;
}

const Wiki = ({ programId }: Props): React.ReactElement => {
  const wiki = WIKIS[programId];
  const program = PROGRAMS[programId];

  const title = program.name + " (" + program.anime + ") ~ Anime on Ice";
  const description = program.blurb;
  const image_link = wiki.cover_art_link;

  const top = React.createRef<HTMLDivElement>();
  const bottom = React.createRef<HTMLDivElement>();

  return (
    <div className={wiki.name} ref={top}>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:image" content={image_link} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image_link} />
      </Helmet>
      <div className="wiki">
        <WikiHeader program={program} top={top} bottom={bottom} />
        <div className="wiki-columns">
          <div className="wiki-overview">
            <WikiMetadata program={program} />
            <div className={classnames("wiki-toc-container", "desktop-only")}>
              <hr />
              <a onClick={scrollTo(top)}>
                <i>Back to top</i>
              </a>
              <div className={classnames("wiki-toc")} />
              {/* Table of contents populated by utils/tocScript.ts */}
              <a onClick={scrollTo(bottom)}>
                <i>See cover art</i>
              </a>
            </div>
          </div>
          <div className="wiki-main">
            <WikiIntro program={program} />
            <hr />
            <WikiPublished program={program} wiki={wiki} />
            <WikiContent wiki={wiki} />
            <WikiNav programId={programId} />
          </div>
        </div>
      </div>
      <WikiFooter wiki={wiki} bottom={bottom} />
    </div>
  );
};

export default Wiki;
