import React from "react";

import Copyright from "../Copyright/Copyright";

import { WikiSpec } from "../../utils/navigation";

interface Props {
  wiki: WikiSpec;
  bottom: React.RefObject<HTMLDivElement>;
}

const WikiFooter = ({ wiki, bottom }: Props): React.ReactElement => {
  return (
    <div className="wiki-cover-art">
      <div className="wiki-credits" ref={bottom}>
        {wiki.cover_credit ? (
          <div className="wiki-artist">
            Artwork by{" "}
            {wiki.artist_link ? (
              <a
                href={wiki.artist_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {wiki.cover_credit}
              </a>
            ) : (
              wiki.cover_credit
            )}
          </div>
        ) : null}
        <Copyright />
      </div>
    </div>
  );
};

export default WikiFooter;
