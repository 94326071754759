import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";

import { programIds, ProgramId, PROGRAMS } from "../../utils/programs";
import { WIKIS } from "../../utils/navigation";

interface Props {
  programId: ProgramId;
}

const WikiNav = ({ programId }: Props): React.ReactElement => {
  const programIdx = programIds.indexOf(programId);
  const prevProgramId = programIdx > 0 ? programIds[programIdx - 1] : null;
  const nextProgramId =
    programIdx < programIds.length - 1 ? programIds[programIdx + 1] : null;

  return (
    <div className="wiki-nav">
      {prevProgramId && WIKIS[prevProgramId].enabled && (
        <a className="wiki-nav-prev" href={WIKIS[prevProgramId].path}>
          <h1>
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
          </h1>
          <div className="wiki-nav-text">
            <h3>{PROGRAMS[prevProgramId].name}</h3>
            <p>
              <i>{PROGRAMS[prevProgramId].anime}</i>
            </p>
          </div>
          <img className="image" src={PROGRAMS[prevProgramId].thumbnail} />
        </a>
      )}
      {nextProgramId && WIKIS[nextProgramId].enabled && (
        <a className="wiki-nav-next" href={WIKIS[nextProgramId].path}>
          <img className="image" src={PROGRAMS[nextProgramId].thumbnail} />
          <div className="wiki-nav-text">
            <h3>{PROGRAMS[nextProgramId].name}</h3>
            <p>
              <i>{PROGRAMS[nextProgramId].anime}</i>
            </p>
          </div>
          <h1>
            <FontAwesomeIcon icon={faAngleDoubleRight} />
          </h1>
        </a>
      )}
    </div>
  );
};

export default WikiNav;
