export const FEATURES = {
  socials: true,
  events: false, // events section on home page
  friend_a: true,
  unravel: true,
  history_makers: true,
  nirvana: true,
  gate_of_steiner: true,
  moral_impasse: true,
  moments: true,
  barricades: true,
  one_step_closer: false,
  bye_bye_yesterday: false,
  grand_escape: false,
  guiding_light: false,
  silhouette: false,
  lit: false,
  unbreakable_spirit: false,
};
