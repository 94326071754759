import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

import Socials from "../Socials/Socials";
import {
  sectionIds,
  SectionSpec,
  HOME_PAGE_SECTIONS,
  scrollTo,
  inSection,
} from "../../utils/navigation";
import { FEATURES } from "../../utils/features";

const NavBar = (): React.ReactElement => {
  const navBar = React.createRef<HTMLDivElement>();
  const toggleMenu = () => {
    if (navBar.current) {
      const navBarElement = navBar.current;
      if (navBarElement.className === "navbar") {
        navBarElement.className += " nav-open";
      } else {
        navBarElement.className = "navbar";
      }
    }
  };
  const scrollAndToggleMenu = (section: SectionSpec) => {
    return (): void => {
      navBar.current &&
        navBar.current.className === "navbar nav-open" &&
        toggleMenu();
      section && scrollTo(section.ref)();
    };
  };

  const [visibleSection, setVisibleSection] = useState("");
  useEffect(() => {
    const handleScroll = () => {
      // First check if in last section (which may not overlap with center of browser)
      // Then check remaining sections
      const scrollPosition = window.scrollY;
      const selected = inSection(
        HOME_PAGE_SECTIONS.contact.ref.current,
        scrollPosition + window.innerHeight * 0.8
      )
        ? "contact"
        : sectionIds.find((sectionId) => {
            const element = HOME_PAGE_SECTIONS[sectionId].ref.current;
            return inSection(element, scrollPosition + window.innerHeight / 2);
          });

      if (selected && selected !== visibleSection) {
        setVisibleSection(selected);
      } else if (!selected && visibleSection) {
        setVisibleSection("");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [visibleSection]);

  return (
    <div className="navbar" ref={navBar}>
      <ul className="nav-list">
        <li className={classnames("mobile-only", "nav-title")}>
          <a
            className="nav-section-link"
            onClick={scrollAndToggleMenu(HOME_PAGE_SECTIONS.about)}
          >
            <h2>ANIME ON ICE</h2>
          </a>
        </li>
        {sectionIds.map((sectionId) => {
          // Skip the events section if it's not enabled
          if (
            sectionId !== "events" ||
            (sectionId === "events" && FEATURES.events)
          ) {
            const section = HOME_PAGE_SECTIONS[sectionId];
            return (
              <li
                className={classnames({
                  "nav-section": true,
                  "nav-selected": visibleSection === sectionId,
                  "mobile-disabled": section.mobile_disabled,
                })}
                key={sectionId}
              >
                <a
                  className="nav-section-link"
                  onClick={scrollAndToggleMenu(section)}
                >
                  <p className="nav-section-label">{section.name}</p>
                </a>
                {section.mobile_disabled && (
                  <div className="mobile-only">
                    <p className="nav-section-label">{section.name}</p>
                    <p>
                      <i>Viewable only on desktop</i>
                    </p>
                  </div>
                )}
              </li>
            );
          }
        })}
        <div className={classnames("mobile-only")}>
          <Socials />
        </div>
      </ul>
      <h2
        className={classnames("mobile-only", "navbar-menu")}
        onClick={toggleMenu}
      >
        <a>
          <FontAwesomeIcon icon={faBars} className="navbar-closed" />
          <FontAwesomeIcon icon={faTimes} className="navbar-opened" />
        </a>
      </h2>
    </div>
  );
};

export default NavBar;
