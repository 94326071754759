import React from "react";

import mitfsc from "../../media/thumbnails/mitfsc-winter-2022.png";
import { HOME_PAGE_SECTIONS } from "../../utils/navigation";

const EventsSection = (): React.ReactElement => (
  <div
    className="eventsSection"
    ref={HOME_PAGE_SECTIONS.events.ref}
    id="events"
  >
    <div className="header">
      <h1 className="title">Upcoming Events</h1>
    </div>
    <div className="event-card">
      <div className="thumbnail">
        <a
          href="https://www.facebook.com/events/1069014876991788"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="image"
            src={mitfsc}
            alt="MITFSC Winter 2022 Exhibition Flyer"
          />
        </a>
      </div>
      <div className="event-description">
        <div className="event-header">
          <h2>Live Performance @ MIT Johnson Ice Rink</h2>
          <h3>
            <i>
              Grand Escape •{" "}
              <span className="no-wrap">Weathering with You</span>
            </i>
          </h3>
        </div>
        <p className="program-blurb">
          A girl has the special ability to bring happiness to those around her.
          At first, everyone is excited, but soon, excitement turns into
          expectations, and then into crippling dependence. What happens when a
          superpower proves to be both a blessing and a curse?
        </p>
        <br />
        <p>Date: Saturday, March 12, 2022</p>
        <p>Time: 1:30pm EST (Doors open 1:00pm)</p>
        <p>Address: 120 Vassar St, Cambridge, MA 02139</p>
        <p>Admission: FREE</p>
        <p>
          Registration via{" "}
          <a
            href="https://visitors.mit.edu/?event=6a57ffb9-bf8d-42bb-8c4b-3c8844636167"
            target="_blank"
            rel="noopener noreferrer"
          >
            Tim Tickets
          </a>{" "}
          required
        </p>
      </div>
    </div>
  </div>
);

export default EventsSection;
