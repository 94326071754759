import React from "react";
import remark from "remark";
import toHast from "mdast-util-to-hast";
import toHtml from "hast-util-to-html";
import parse from "html-react-parser";
import slug from "rehype-slug";
import rehype from "rehype";

import { WikiSpec } from "../../utils/navigation";

interface Props {
  wiki: WikiSpec;
}

// Add ids to the headings (i.e. "slug")
// This is needed in order to auto-generate table of contents
const WikiContent = ({ wiki }: Props): React.ReactElement => {
  const mdAST = remark.parse(wiki.file);
  const hAST = toHast(mdAST, { allowDangerousHtml: true });
  const html = toHtml(hAST, { allowDangerousHtml: true });
  const htmlString = rehype()
    .data("settings", { fragment: true })
    .use(slug)
    .processSync(html)
    .contents.toString();
  return <div className="wiki-content">{parse(htmlString)}</div>;
};

export default WikiContent;
