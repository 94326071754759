import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleRight,
  faBook,
  faBookOpen,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";

import { ProgramId, PROGRAMS } from "../../utils/programs";
import { WIKIS } from "../../utils/navigation";

interface Props {
  programId: ProgramId;
}

const ProgramDescription = ({ programId }: Props): React.ReactElement => {
  const program = PROGRAMS[programId];
  const wiki = WIKIS[programId];

  return (
    <div className="program-description">
      <div className="program-header">
        <h2>
          {program.name} • <span className="no-wrap">{program.anime}</span>{" "}
        </h2>
        <h3>
          <i>{program.theme}</i>
        </h3>
      </div>
      <p className="program-blurb">{program.blurb}</p>
      <p className="program-link">
        {program.context && wiki.enabled ? (
          <>
            <FontAwesomeIcon icon={faBook} />
            <FontAwesomeIcon icon={faBookOpen} /> {program.context}{" "}
            <FontAwesomeIcon icon={faAngleDoubleRight} />
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faExternalLinkAlt} /> View Video
          </>
        )}
      </p>
    </div>
  );
};

export default ProgramDescription;
