import React from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListAlt } from "@fortawesome/free-regular-svg-icons";
import {
  faAngleLeft,
  faListAlt as faListAltSolid,
} from "@fortawesome/free-solid-svg-icons";

import Logo from "../Logo/Logo";
import Socials from "../Socials/Socials";

import { scrollTo } from "../../utils/navigation";
import { ProgramSpec } from "../../utils/programs";

interface Props {
  program: ProgramSpec;
  top: React.RefObject<HTMLDivElement>;
  bottom: React.RefObject<HTMLDivElement>;
}

const WikiHeader = ({ program, top, bottom }: Props): React.ReactElement => {
  const headerMobile = React.createRef<HTMLDivElement>();

  const toggleMenu = () => {
    if (headerMobile.current) {
      const headerElement = headerMobile.current;
      if (headerElement.className === "wiki-header-container mobile-only") {
        headerElement.className += " toc-open";
      } else {
        headerElement.className = "wiki-header-container mobile-only";
      }
    }
  };

  return (
    <>
      <div className={classnames("wiki-header-container", "desktop-only")}>
        <div className="wiki-header">
          <div className="wiki-logo">
            <a href="/">
              <Logo />
            </a>
            <Socials />
          </div>
          <div className="wiki-title">
            <a onClick={scrollTo(top)}>
              <h1 className="title">
                <span className="highlight">{program.anime}</span> on Ice
              </h1>
            </a>
            <p className="subtitle">{program.quote_personal}</p>
          </div>
        </div>
      </div>

      <div
        className={classnames("wiki-header-container", "mobile-only")}
        ref={headerMobile}
      >
        <div className="wiki-header">
          <h2>
            <a href="/">
              <FontAwesomeIcon icon={faAngleLeft} />
            </a>
          </h2>
          <div className="wiki-title">
            <a onClick={scrollTo(top)}>
              <h2 className="title">
                <span className="highlight">{program.anime}</span> on Ice
              </h2>
            </a>
          </div>
          <h2 onClick={toggleMenu}>
            <a>
              <FontAwesomeIcon className="toc-closed-icon" icon={faListAlt} />
              <FontAwesomeIcon
                className="toc-open-icon"
                icon={faListAltSolid}
              />
            </a>
          </h2>
        </div>
        <div className="wiki-toc-container" onClick={toggleMenu}>
          <a onClick={scrollTo(top)}>
            <i>Back to top</i>
          </a>
          <div className="wiki-toc" />
          {/* Table of contents populated by utils/tocScript.ts */}
          <a onClick={scrollTo(bottom)}>
            <i>See cover art</i>
          </a>
          <Socials />
          <div className="wiki-toc-logo">
            <a href="/">ANIME ON ICE</a>
          </div>
        </div>
      </div>
    </>
  );
};

export default WikiHeader;
