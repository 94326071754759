import React, { useLayoutEffect } from "react";

import * as ANIMATIONS from "../../../utils/animations";

interface Props {
  isSelected?: boolean;
}

const ProgressBar = ({ isSelected }: Props): React.ReactElement => {
  const progress: React.RefObject<HTMLDivElement> = React.createRef();

  const reset = () => {
    const progressElement = progress.current;
    if (progressElement) {
      ANIMATIONS.resetAnimation(
        progressElement,
        "progress " + ANIMATIONS.SLIDE_DURATION + "s linear both"
      );
    }
  };

  useLayoutEffect(() => {
    isSelected && reset();
  }, [isSelected]);

  return (
    <div className="progress-div">
      <div className="progress" ref={progress} />
    </div>
  );
};

export default ProgressBar;
