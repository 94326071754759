import React from "react";
import { Carousel } from "react-responsive-carousel";
import stylesCarousel from "react-responsive-carousel/lib/styles/carousel.min.css";

import GalleryItem from "./GalleryItem/GalleryItem";
import GalleryLogo from "./GalleryLogo/GalleryLogo";

import { HOME_PAGE_SECTIONS, WIKIS } from "../../utils/navigation";
import { programIds, PROGRAMS } from "../../utils/programs";
import * as ANIMATIONS from "../../utils/animations";

const Gallery = (): React.ReactElement => {
  const gallery = React.createRef<Carousel>();
  const renderItem = (item, props) => <item.type {...item.props} {...props} />;
  const renderThumbs = (items) =>
    items.map((item) => (
      <img src={item.props.program.thumbnail} key={item.props.program.name} />
    ));
  const onClickThumb = (index, item) => {
    gallery.current && gallery.current.moveTo(index, false);
  };
  const defaultInterval = ANIMATIONS.SLIDE_DURATION * 1000;

  return (
    <div className="gallery" ref={HOME_PAGE_SECTIONS.gallery.ref}>
      <Carousel
        ref={gallery}
        className={stylesCarousel}
        renderItem={renderItem}
        renderThumbs={renderThumbs}
        showStatus={false}
        showIndicators={false}
        stopOnHover={false}
        infiniteLoop
        autoPlay
        useKeyboardArrows
        interval={defaultInterval}
        onClickThumb={onClickThumb}
      >
        {programIds.map((programId) => (
          <GalleryItem
            program={PROGRAMS[programId]}
            wiki={WIKIS[programId]}
            key={programId}
          />
        ))}
      </Carousel>
      <GalleryLogo />
    </div>
  );
};

export default Gallery;
