import React from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { faYoutube, faLinkedin } from "@fortawesome/free-brands-svg-icons";

import kawaii from "../../media/dns/kawaii.png";
import { BIOS } from "../../utils/bios";
import { HOME_PAGE_SECTIONS } from "../../utils/navigation";

const AboutSection = (): React.ReactElement => (
  <div className="about-container" ref={HOME_PAGE_SECTIONS.about.ref}>
    <div className="about">
      <div className="welcome">
        <h1 className="paragraph">
          Welcome to{" "}
          <span className={classnames("highlight", "no-wrap")}>
            ~Anime on Ice~
          </span>{" "}
          where Diane and Sabina are on a mission to create anime adaptations on
          ice!
        </h1>
        <h2 className="paragraph">
          <i>
            Translating meaningful messages from anime shows and music into
            performances that figure skating fans can also ponder and enjoy
          </i>
        </h2>
      </div>
      <div className="about-dns">
        <img src={kawaii} alt="Kawaii selfie" />
        <div className="about-dns-bios">
          {BIOS.map((bio) => (
            <h3 key={bio.name} className="about-dns-bio">
              <div className="about-dns-bio-name">
                <span className="highlight">{bio.name.toUpperCase()}</span>
                <span className="about-dns-bio-links">
                  {bio.website ? (
                    <a
                      href={bio.website}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faGlobe} />
                    </a>
                  ) : null}
                  {bio.youtube ? (
                    <a
                      href={bio.youtube}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>
                  ) : null}
                  {bio.linkedin ? (
                    <a
                      href={bio.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                  ) : null}
                </span>
              </div>
              <p className="about-dns-bio-description">{bio.bio}</p>
            </h3>
          ))}
        </div>
        <p className={classnames("paragraph", "thanks")}>
          Special thanks to the{" "}
          <a
            className="no-wrap"
            href="http://web.mit.edu/skatingclub/www/"
            target="_blank"
            rel="noopener noreferrer"
          >
            MIT Figure Skating Club
          </a>{" "}
          for fostering a remarkably supportive and inclusive community and for
          hosting all of the events at which we had the privilege of showcasing
          our creations.
        </p>
      </div>
    </div>
  </div>
);

export default AboutSection;
