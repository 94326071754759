type BioSpec = {
  name: string;
  bio: string;
  youtube?: string;
  linkedin?: string;
  website?: string;
};

export const BIOS: BioSpec[] = [
  {
    name: "Diane Zhou",
    bio:
      "From solo competitor in her teenage years to " +
      "president of the MIT Figure Skating Club during college to " +
      "manager of the SF Ice Theatre's adult team now, " +
      "Diane values figure skating for its athleticism, artistry, and ability to bring people together. " +
      "She appreciates that anime can address uncomfortable but meaningful topics " +
      "to inspire reflection on ourselves, society, and humanity. " +
      "Bringing skating and anime together, Diane is excited to share slices of life through “Anime on Ice” " +
      "and hopes it spreads empathy across different cultures and communities. ",
    youtube: "https://www.youtube.com/c/dianezhou",
    linkedin: "https://www.linkedin.com/in/dianeyzhou/",
  },
  {
    name: "Sabina Chen",
    bio:
      "Sabina enjoys expressing herself through the creative arts. " +
      "She views anime as another means to explore more difficult societal topics in a constructive and meaningful way. " +
      "Fascinated with learning about how technology and the human imagination can empower each other, " +
      "she loves working on projects that bring ideas from anime to life via designing, tinkering, and storytelling. " +
      "Sabina hopes that “Anime on Ice” can be a creative medium not only to tell her own stories, " +
      "but also to connect with the hearts and souls of the people around her.",
    linkedin: "https://www.linkedin.com/in/sabinachen/",
    website: "https://www.sabinachen.com",
  },
];
