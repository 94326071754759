import React, { useEffect, useState } from "react";
import classnames from "classnames";

import ProgramCard from "./ProgramCard";

import { HOME_PAGE_SECTIONS, WIKIS, inSection } from "../../utils/navigation";
import { programIds, PROGRAMS } from "../../utils/programs";

const ProgramsSection = (): React.ReactElement => {
  const sectionRefs = programIds.map((programId) => ({
    name: PROGRAMS[programId].name,
    ref: React.createRef<HTMLLIElement>(),
  }));
  const [visibleSection, setVisibleSection] = useState("");
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 2;
      const selected = sectionRefs.find(({ ref }) => {
        const element = ref.current;
        return inSection(element, scrollPosition);
      });

      if (selected && selected.name !== visibleSection) {
        setVisibleSection(selected.name);
      } else if (!selected && visibleSection) {
        setVisibleSection("");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [visibleSection]);

  return (
    <div
      className="programs"
      ref={HOME_PAGE_SECTIONS.programs.ref}
      id="programs"
    >
      <div className="header">
        <h1 className="title">
          Our{" "}
          <span className={classnames("highlight", "no-wrap")}>
            ~Anime on Ice~
          </span>{" "}
          Programs
        </h1>
      </div>
      <p className="paragraph">
        Sharing the behind-the-scenes about our creative process and
        inspirations...
      </p>
      <div className="program-cards">
        <ol>
          {programIds.map(
            (programId, index) =>
              WIKIS[programId].enabled && (
                <li
                  key={PROGRAMS[programId].name}
                  ref={sectionRefs[index].ref}
                  className={classnames({
                    selected: PROGRAMS[programId].name === visibleSection,
                  })}
                >
                  <ProgramCard programId={programId} />
                </li>
              )
          )}
        </ol>
      </div>
      <h1 className="highlight">~</h1>
      <p className="paragraph">
        Behind-the-scenes content is coming soon for the rest of our programs!
        <br />
        In the meantime, enjoy these previews that link to videos of our
        performances.
      </p>
      <div className="program-cards">
        <ol>
          {programIds.map(
            (programId, index) =>
              !WIKIS[programId].enabled && (
                <li
                  key={PROGRAMS[programId].name}
                  ref={sectionRefs[index].ref}
                  className={classnames({
                    selected: PROGRAMS[programId].name === visibleSection,
                  })}
                >
                  <ProgramCard programId={programId} />
                </li>
              )
          )}
        </ol>
      </div>
    </div>
  );
};

export default ProgramsSection;
