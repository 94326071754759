import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import { FEATURES } from "../../utils/features";

const Socials = (): React.ReactElement =>
  FEATURES.socials ? (
    <p className="socials">
      <a
        href="https://www.instagram.com/animeonice"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faInstagram} />
      </a>{" "}
      <a
        href="https://www.facebook.com/animeonice"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faFacebook} />
      </a>{" "}
      <a
        href="https://twitter.com/animeoniceskate"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faTwitter} />
      </a>{" "}
      <a
        href="https://www.youtube.com/@animeonice"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faYoutube} />
      </a>{" "}
    </p>
  ) : (
    <></>
  );

export default Socials;
