import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Switch, Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import smoothscroll from "smoothscroll-polyfill";

import Home from "./components/Home/Home";
import Wiki from "./components/Wiki/Wiki";

import { programIds } from "./utils/programs";
import { WIKIS } from "./utils/navigation";

import "./styles/styles.scss";

smoothscroll.polyfill();

const AnimeOnIceApp = (): React.ReactElement => (
  <Switch>
    <Route exact path="/">
      <Home />
    </Route>
    {programIds
      .filter((programId) => WIKIS[programId].enabled)
      .map((programId) => (
        <Route exact key={programId} path={WIKIS[programId].path}>
          <Wiki programId={programId} />
        </Route>
      ))}
    <Route exact key="events" path="/events">
      <Redirect to="/#events" />
    </Route>
    <Route>
      <h1>PAGE NOT FOUND</h1>
    </Route>
  </Switch>
);

const title = "Anime on Ice";
const description =
  "Diane and Sabina create anime adaptations on ice to translate meaningful messages from anime shows and songs into performances that figure skating fans can also ponder and enjoy.";
const image_link = "https://animeonice.com/cover-arts/history-makers.jpeg";

ReactDOM.render(
  <BrowserRouter>
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:image" content={image_link} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image_link} />
    </Helmet>
    <AnimeOnIceApp />
  </BrowserRouter>,
  document.getElementById("root")
);
