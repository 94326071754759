import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright } from "@fortawesome/free-regular-svg-icons";
import { BIOS } from "../../utils/bios";

const Copyright = (): React.ReactElement => (
  <p className="copyright">
    Copyright <FontAwesomeIcon icon={faCopyright} /> 2023{" "}
    <a className="highlight" href="/">
      Anime on Ice
    </a>{" "}
    • <span className="no-wrap">Website designed and maintained</span> by{" "}
    <span className="no-wrap">
      <a
        href={BIOS.filter((bio) => bio.name === "Diane Zhou")[0].linkedin}
        target="_blank"
        rel="noopener noreferrer"
      >
        Diane Zhou
      </a>{" "}
      and{" "}
      <a
        href={BIOS.filter((bio) => bio.name === "Sabina Chen")[0].website}
        target="_blank"
        rel="noopener noreferrer"
      >
        Sabina Chen
      </a>
    </span>
  </p>
);

export default Copyright;
