import React from "react";

import NavBar from "../NavBar/NavBar";
import Gallery from "../Gallery/Gallery";
import AboutSection from "../AboutSection/AboutSection";
import EventsSection from "../EventsSection/EventsSection";
import ProgramsSection from "../ProgramsSection/ProgramsSection";
import Footer from "../Footer/Footer";
import { FEATURES } from "../../utils/features";
import useWindowDimensions from "../../utils/useWindowDimensions";

const Home = (): React.ReactElement => {
  const { width } = useWindowDimensions();
  return (
    <div className="home">
      {width > 992 && <Gallery />}
      <NavBar />
      <main>
        <AboutSection />
        {FEATURES.events && <EventsSection />}
        <ProgramsSection />
        <Footer />
      </main>
    </div>
  );
};

export default Home;
