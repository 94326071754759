import React from "react";
import fs from "fs";

import { ProgramId } from "./programs";
import { FEATURES } from "./features";

export const sectionIds = [
  "gallery",
  "about",
  "events",
  "programs",
  "contact",
] as const;
type SectionId = typeof sectionIds[number];

export type SectionSpec = {
  name: string;
  ref: React.RefObject<HTMLDivElement>;
  mobile_disabled?: boolean;
};

export const HOME_PAGE_SECTIONS: { [key in SectionId]: SectionSpec } = {
  gallery: {
    name: "Gallery",
    ref: React.createRef<HTMLDivElement>(),
    mobile_disabled: true,
  },
  about: {
    name: "About",
    ref: React.createRef<HTMLDivElement>(),
  },
  events: {
    name: "Events",
    ref: React.createRef<HTMLDivElement>(),
  },
  programs: {
    name: "Programs",
    ref: React.createRef<HTMLDivElement>(),
  },
  contact: {
    name: "Contact",
    ref: React.createRef<HTMLDivElement>(),
  },
};

export type WikiSpec = {
  name: string;
  path: string;
  file: string;
  enabled: boolean;
  author?: string;
  date?: string;
  cover_credit?: string;
  artist_link?: string;
  cover_art_link?: string; // used for link preview
};

export const WIKIS: { [key in ProgramId]: WikiSpec } = {
  friend_a: {
    name: "friend-a",
    path: "/programs/friend-a",
    file: fs.readFileSync("src/wikis/FriendA.md", "utf-8"),
    enabled: FEATURES.friend_a,
    author: "Diane Zhou and Sabina Chen",
    date: "April 2021",
    cover_credit: "Rachel Wu",
    artist_link: "https://www.behance.net/yorustar",
    cover_art_link: "https://animeonice.com/cover-arts/friend-a.jpg",
  },
  unravel: {
    name: "unravel",
    path: "/programs/unravel",
    file: fs.readFileSync("src/wikis/Unravel.md", "utf-8"),
    enabled: FEATURES.unravel,
    author: "Diane Zhou and Sabina Chen",
    date: "July 2021",
    cover_credit: "Flora Su",
    artist_link: "https://www.deviantart.com/midnighttiger8140",
    cover_art_link: "https://animeonice.com/cover-arts/unravel.jpg",
  },
  history_makers: {
    name: "history-makers",
    path: "/programs/history-makers",
    file: fs.readFileSync("src/wikis/HistoryMakers.md", "utf-8"),
    enabled: FEATURES.history_makers,
    author: "Diane Zhou",
    date: "September 2021",
    cover_credit: "Chloris Li",
    artist_link: "https://www.instagram.com/ichlorisli",
    cover_art_link: "https://animeonice.com/cover-arts/history-makers.jpeg",
  },
  nirvana: {
    name: "nirvana",
    path: "/programs/nirvana",
    file: fs.readFileSync("src/wikis/Nirvana.md", "utf-8"),
    enabled: FEATURES.nirvana,
    author: "Diane Zhou and Sabina Chen",
    date: "October 2021",
    cover_credit: "Usanekorin of Collateral Damage Studios",
    artist_link: "https://www.collateralds.com/about",
    cover_art_link: "https://animeonice.com/cover-arts/nirvana.jpg",
  },
  gate_of_steiner: {
    name: "gate-of-steiner",
    path: "/programs/gate-of-steiner",
    file: fs.readFileSync("src/wikis/GateOfSteiner.md", "utf-8"),
    enabled: FEATURES.gate_of_steiner,
    author: "Diane Zhou",
    date: "March 2022",
    cover_credit: "Julie Liu",
    artist_link: "https://www.instagram.com/juliejraws/",
    cover_art_link: "https://animeonice.com/cover-arts/gate-of-steiner.png",
  },
  moral_impasse: {
    name: "moral-impasse",
    path: "/programs/moral-impasse",
    file: fs.readFileSync("src/wikis/MoralImpasse.md", "utf-8"),
    enabled: FEATURES.moral_impasse,
    author: "Diane Zhou and Sabina Chen",
    date: "August 2022",
    cover_credit: "Michelle Ly",
    artist_link: "https://www.instagram.com/monchly/",
    cover_art_link: "https://animeonice.com/cover-arts/moral-impasse.png",
  },
  moments: {
    name: "moments",
    path: "/programs/moments",
    file: fs.readFileSync("src/wikis/Moments.md", "utf-8"),
    enabled: FEATURES.moments,
    author: "Diane Zhou",
    date: "October 2022",
    cover_credit: "Sarah Don",
    artist_link: "https://www.instagram.com/triseradon/",
    cover_art_link: "https://animeonice.com/cover-arts/moments.jpg",
  },
  barricades: {
    name: "barricades",
    path: "/programs/barricades",
    file: fs.readFileSync("src/wikis/Barricades.md", "utf-8"),
    enabled: FEATURES.barricades,
    author: "Sabina Chen",
    date: "March 2023",
    cover_credit: "H.J.",
    artist_link: "https://twitter.com/HJ_767",
    cover_art_link: "https://animeonice.com/cover-arts/barricades.jpg",
  },
  one_step_closer: {
    name: "one-step-closer",
    path: "/programs/one-step-closer",
    file: fs.readFileSync("src/wikis/HelloWorld.md", "utf-8"),
    enabled: FEATURES.one_step_closer,
    cover_credit: "Photograph by Alex Huang",
  },
  bye_bye_yesterday: {
    name: "bye-bye-yesterday",
    path: "/programs/bye-bye-yesterday",
    file: fs.readFileSync("src/wikis/HelloWorld.md", "utf-8"),
    enabled: FEATURES.bye_bye_yesterday,
  },
  grand_escape: {
    name: "grand-escape",
    path: "/programs/grand-escape",
    file: fs.readFileSync("src/wikis/HelloWorld.md", "utf-8"),
    enabled: FEATURES.grand_escape,
  },
  guiding_light: {
    name: "guiding-light",
    path: "/programs/guiding-light",
    file: fs.readFileSync("src/wikis/HelloWorld.md", "utf-8"),
    enabled: FEATURES.guiding_light,
  },
  silhouette: {
    name: "silhouette",
    path: "/programs/silhouette",
    file: fs.readFileSync("src/wikis/HelloWorld.md", "utf-8"),
    enabled: FEATURES.silhouette,
  },
  lit: {
    name: "lit",
    path: "/programs/lit",
    file: fs.readFileSync("src/wikis/HelloWorld.md", "utf-8"),
    enabled: FEATURES.lit,
  },
  unbreakable_spirit: {
    name: "unbreakable-spirit",
    path: "/programs/unbreakable-spirit",
    file: fs.readFileSync("src/wikis/HelloWorld.md", "utf-8"),
    enabled: FEATURES.unbreakable_spirit,
  },
};

export function scrollTo(ref: React.RefObject<HTMLDivElement>) {
  return (): void => {
    ref.current && ref.current.scrollIntoView({ behavior: "smooth" });
  };
}

function getDimensions(
  element: HTMLElement
): { height: number; offsetTop: number; offsetBottom: number } {
  const { height } = element.getBoundingClientRect();
  const offsetTop = element.offsetTop;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
}

export function inSection(
  element: HTMLElement | null,
  scrollPosition: number
): boolean {
  if (element) {
    const { offsetBottom, offsetTop } = getDimensions(element);
    return scrollPosition > offsetTop && scrollPosition < offsetBottom;
  }
  return false;
}
