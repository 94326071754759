import React from "react";

import Thumbnail from "../Thumbnail/Thumbnail";
import ProgramDescription from "./ProgramDescription";

import { ProgramId, PROGRAMS } from "../../utils/programs";
import { WIKIS } from "../../utils/navigation";

interface Props {
  programId: ProgramId;
}

const ProgramCard = ({ programId }: Props): React.ReactElement => (
  <a
    href={
      WIKIS[programId].enabled
        ? WIKIS[programId].path
        : "https://youtu.be/" + PROGRAMS[programId].video_id
    }
    target={WIKIS[programId].enabled ? "_self" : "_blank"}
    rel="noopener noreferrer"
  >
    <div className="program-card">
      <Thumbnail program={PROGRAMS[programId]} />
      <ProgramDescription programId={programId} />
    </div>
  </a>
);

export default ProgramCard;
