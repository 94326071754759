export const programIds = [
  "friend_a",
  "unravel",
  "history_makers",
  "nirvana",
  "gate_of_steiner",
  "moral_impasse",
  "moments",
  "barricades",
  "one_step_closer",
  "bye_bye_yesterday",
  "grand_escape",
  "guiding_light",
  "silhouette",
  "lit",
  "unbreakable_spirit",
] as const;
export type ProgramId = typeof programIds[number];

export type ProgramSpec = {
  name: string;
  anime: string;
  anime_link?: string;
  song?: string;
  song_link?: string;
  skaters?: string[];
  choreographers?: string[];
  date: string;
  location?: string;
  thumbnail: string; // require("path-to-thumbnail")
  video_id: string; // YouTube video ID
  theme: string;
  blurb: string;
  video_preview: string; // require("url:path-to-video-preview")
  anime_on_left: boolean; // whether anime image is on the left side of thumbnail
  quote_anime: string;
  quote_character: string;
  quote_personal: string;
  context?: string;
};

export const PROGRAMS: { [key in ProgramId]: ProgramSpec } = {
  friend_a: {
    name: "Friend A",
    anime: "Your Lie in April",
    anime_link:
      "https://shigatsu-wa-kimi-no-uso.fandom.com/wiki/Shigatsu_wa_Kimi_no_Uso_(anime)",
    song: "“Yuujin A” (Masaru Yokoyama)",
    song_link: "https://youtu.be/00Bki1-MnEY",
    skaters: [
      "Diane Zhou (as Kaori Miyazono)",
      "Sabina Chen (as Kousei Arima)",
    ],
    choreographers: ["Diane Zhou", "Sabina Chen"],
    date: "December 10, 2016",
    location: "MIT Johnson Ice Rink",
    thumbnail: require("../media/thumbnails/friend-a.jpg"),
    video_id: "RhVCFWeZ3P4",
    theme: "true friendship",
    blurb:
      "Friendships come and go, each shaping us in different ways. Some leave us with fond memories, others with tough lessons. Perhaps the most special friend is the one who lifts us up during our darkest hours. Diane and Sabina express a friendship built not only on fun and happy moments, but also on supporting each other through difficult times.",
    video_preview: require("url:../media/previews/friend-a-preview.mp4"),
    anime_on_left: false,
    quote_anime: "My music… Will it reach them?",
    quote_character: "Kaori Miyazono",
    quote_personal: "Our skating… Did it reach you?",
    context: "How it all began...",
  },
  unravel: {
    name: "Unravel",
    anime: "Tokyo Ghoul",
    anime_link: "https://tokyoghoul.fandom.com/wiki/Tokyo_Ghoul_(anime)",
    song: "“Unravel” (Animenz piano cover, original by TK)",
    song_link: "https://youtu.be/sEQf5lcnj_o",
    skaters: ["Diane Zhou (as human Kaneki)", "Sabina Chen (as ghoul Kaneki)"],
    choreographers: ["Diane Zhou", "Sabina Chen"],
    date: "March 12, 2017",
    location: "MIT Johnson Ice Rink",
    thumbnail: require("../media/thumbnails/tokyo-ghoul.jpg"),
    video_id: "qA7SgAlNJJs",
    theme: "self-doubt & self-acceptance",
    blurb:
      "We have all, at some point in our lives, experienced self-discovery and self-doubt. We seek answers to our inner thoughts and emotions, learn things about ourselves that we are initially afraid to believe, and hopefully come to embrace a truer version of ourselves. Diane and Sabina journey on the oftentimes scary, yet invigorating path towards self-acceptance.",
    video_preview: require("url:../media/previews/unravel-preview.mp4"),
    anime_on_left: true,
    quote_anime: "We need to have a mask that we never take off.",
    quote_character: "Uta",
    quote_personal:
      "A journey on the scary, yet invigorating path towards self-acceptance",
    context: "A look inward to discover ourselves",
  },
  history_makers: {
    name: "History Makers",
    anime: "Yuri!!! on Ice",
    anime_link: "https://yurionice.fandom.com/wiki/Yuri!!!_on_Ice",
    song: "“History Maker” (Dean Fujioka)",
    song_link: "https://youtu.be/dSrrRaVyQHQ",
    skaters: [
      "Ananya Nandy",
      "Cydney Wong",
      "Diane Zhou",
      "Flora Su",
      "Kylie Ying",
      "Liz Martin",
      "Maya Sankar",
      "Mickey Barry",
      "Sabina Chen",
      "Sarah Don",
      "Shawn Pan",
      "Tetsuya Kaji",
    ],
    choreographers: ["Diane Zhou"],
    date: "March 12, 2017",
    location: "MIT Johnson Ice Rink",
    thumbnail: require("../media/thumbnails/history-makers.jpg"),
    video_id: "Ko_IGXLHMkM",
    theme: "teamwork",
    blurb:
      "Ananya, Cydney, Diane, Flora, Kylie, Liz, Maya, Mickey, Sabina, Sarah, Shawn, and Tetsuya make history together by showcasing their individual signature moves, teaming up for awesome lifts and spirals, and hyping up the crowd. Figure skating is more fun as a team and with a community!",
    video_preview: require("url:../media/previews/history-makers-preview.mp4"),
    anime_on_left: true,
    quote_anime:
      "There’s a place you just can’t reach unless you have a dream too large to bear alone.",
    quote_character: "Yuri Katsuki",
    quote_personal: "We were born to make history!",
    context: "Sharing the joy of skating together",
  },
  nirvana: {
    name: "Nirvana",
    anime: "Noragami",
    anime_link: "https://noragami.fandom.com/wiki/Noragami_(Anime)",
    song: "“Nirvana” (Tia)",
    song_link: "https://youtu.be/yazn0uMP-Fk",
    skaters: ["Diane Zhou (as Yato)", "Sabina Chen (as Hiyori Iki)"],
    choreographers: ["Diane Zhou", "Sabina Chen"],
    date: "December 9, 2017",
    location: "MIT Johnson Ice Rink",
    thumbnail: require("../media/thumbnails/nirvana.png"),
    video_id: "1ZaqB1RNonk",
    theme: "pursuit of happiness",
    blurb:
      "In the grind of everyday life, expectations and uncertainty weigh us down. Sometimes they overwhelm us so much that we can't enjoy life for fear of disappointing ourselves and the people we care about. Diane and Sabina find moments of bliss in the face of doubt and anxiety by learning to appreciate the present despite worries for the future.",
    video_preview: require("url:../media/previews/nirvana-preview.mp4"),
    anime_on_left: false,
    quote_anime:
      "Even if things are painful and tough, people should appreciate what it means to be alive.",
    quote_character: "Yato",
    quote_personal:
      "Searching for moments of bliss in the face of doubt and anxiety",
    context: "Living in the moment",
  },
  gate_of_steiner: {
    name: "Gate of Steiner",
    anime: "Steins;Gate",
    anime_link:
      "https://steins-gate.fandom.com/wiki/Steins;Gate_(Anime_Series)",
    song: "“Gate of Steiner” (Symphonic Reunion)",
    song_link: "https://youtu.be/ABuR0nLRV-o",
    skaters: ["Diane Zhou"],
    choreographers: ["Elin Schran"],
    date: "February 4, 2018",
    location: "MIT Johnson Ice Rink",
    thumbnail: require("../media/thumbnails/gate-of-steiner.jpg"),
    video_id: "kVg0a1EKWd4",
    theme: "time & choice",
    blurb:
      "Do our choices still matter when we cannot change the outcome? When time moves forward relentlessly? Although the future seems set in stone, there may be meaningful choices to make that shape our feelings and memories around whatever fate we meet. Diane navigates the choices, or the lack thereof, that life presents.",
    video_preview: require("url:../media/previews/gate-of-steiner-preview.mp4"),
    anime_on_left: true,
    quote_anime: "People’s feelings are memories that transcend time.",
    quote_character: "Kurisu Makise",
    quote_personal: "Navigating the choices, or the lack thereof, in life",
    context: "The choice of Steins;Gate",
  },
  moral_impasse: {
    name: "Moral Impasse",
    anime: "Psycho-Pass",
    anime_link: "https://psychopass.fandom.com/wiki/Psycho-Pass_Wiki",
    song: "Psycho-Pass Main Theme",
    song_link: "https://youtu.be/nArpKw8ObM0",
    skaters: ["Diane Zhou (as Kogami)", "Sabina Chen (as Makishima)"],
    choreographers: ["Diane Zhou", "Sabina Chen"],
    date: "March 4, 2018",
    location: "MIT Johnson Ice Rink",
    thumbnail: require("../media/thumbnails/psycho-pass.png"),
    video_id: "FiTPITSP0l4",
    theme: "conflict & judgment",
    blurb:
      "We each view the world through our own lenses, shaped by our personal experiences and the stories we hear. Is there a universal moral compass that we can use to judge others and ourselves, or are we doomed to face conflict when we meet people too different from us? Diane and Sabina explore the difficulty of understanding and accepting one another.",
    video_preview: require("url:../media/previews/psycho-pass-preview.mp4"),
    anime_on_left: true,
    quote_anime:
      "I wonder what sort of criteria you use to divide people into good and evil.",
    quote_character: "Shogo Makishima",
    quote_personal: "Is there a universal moral compass?",
    context: "Clouded in conflict",
  },
  moments: {
    name: "Moments",
    anime: "Clannad",
    anime_link: "https://clannad.fandom.com/wiki/Clannad",
    song: "“A Palm of a Tiny Hand” (English cover by Dima Lancaster)",
    song_link: "https://youtu.be/4Ydi2SLtcRQ",
    skaters: ["Diane Zhou"],
    choreographers: ["Elin Schran"],
    date: "March 4, 2018",
    location: "MIT Johnson Ice Rink",
    thumbnail: require("../media/thumbnails/clannad.png"),
    video_id: "Dtrchi8Gpq8",
    theme: "nostalgia",
    blurb:
      "Change is inevitable and sometimes tough to cope with. How can we enjoy what we have now when we know it will be gone in the future? Diane hopes to find the strength to appreciate the small moments that we share with people in our lives now, even if, or perhaps because, they may one day leave us behind.",
    video_preview: require("url:../media/previews/moments-preview.mp4"),
    anime_on_left: false,
    quote_anime:
      "But no matter what, please don't ever regret the time we spent together.",
    quote_character: "Nagisa Furukawa",
    quote_personal:
      "Appreciating the small moments we share with people in our lives",
    context: "Reminiscing without regret",
  },
  barricades: {
    name: "Barricades",
    anime: "Attack on Titan",
    anime_link: "https://attackontitan.fandom.com/wiki/Attack_on_Titan_(Anime)",
    song: "“Barricades” (Hiroyuki Sawano)",
    song_link: "https://attackontitan.fandom.com/wiki/Barricades",
    skaters: ["Diane Zhou", "Flora Su", "Sabina Chen"],
    choreographers: ["Sabina Chen"],
    date: "March 16, 2019",
    location: "MIT Johnson Ice Rink",
    thumbnail: require("../media/thumbnails/attack-on-titan.jpg"),
    video_id: "6GokoxBKZoU",
    theme: "facing fear",
    blurb:
      "Sometimes we may feel surrounded by walls that prevent us from living on our own terms. For Sabina, it's finding her passions in the face of personal and societal expectations. For Diane, it's caring for the people she loves without losing herself. For Flora, it's self-doubt and fear that she's not enough. Sabina, Diane, and Flora build the courage to break through these walls and live free.",
    video_preview: require("url:../media/previews/barricades-preview.mp4"),
    anime_on_left: true,
    quote_anime:
      "The only thing we're allowed to do is believe that we won't regret the choice we made.",
    quote_character: "Levi Ackerman",
    quote_personal: "Break through these walls and live free!",
    context: "Finding strength in adversity",
  },
  one_step_closer: {
    name: "One Step Closer",
    anime: "Fruits Basket",
    song: "One Step Closer (INTERSECTION)",
    skaters: ["Diane Zhou (as Yuki Sohma)", "Sabina Chen (as Kyo Sohma)"],
    choreographers: ["Diane Zhou", "Sabina Chen"],
    date: "Winter 2019",
    thumbnail: require("../media/thumbnails/fruits-basket.png"),
    video_id: "Hnptu1XJhfg",
    theme: "healing & growing",
    blurb:
      "Overcoming challenges requires healing the pain of the past, as well as growing from the burdens of the present. It can be extremely discouraging because results are often unnoticeable when we are most desperate to see them. Diane and Sabina acknowledge that healing is hard and growth is gradual, but they can keep moving forward one step at a time.",
    video_preview: require("url:../media/previews/one-step-closer-preview.mp4"),
    anime_on_left: false,
    quote_anime:
      "Falling, tripping, losing the way, making mistakes, little by little, walking one step at a time, this is the only way to live out life.",
    quote_character: "Yuki Sohma",
    quote_personal:
      "Healing is hard, growth is gradual, every step forward is one step closer",
    context: "The end... or perhaps just the beginning",
  },
  bye_bye_yesterday: {
    name: "Bye Bye Yesterday",
    anime: "Assassination Classroom",
    date: "December 5, 2021",
    thumbnail: require("../media/thumbnails/bye-bye-yesterday.png"),
    video_id: "MzjszXy6wEA",
    theme: "coming of age",
    blurb:
      "Diane and Sabina go “back to school” to perform a delayed graduation program! With costume changes and flying grad caps, it's all about the craziness of growing up, graduating, and entering adult life.",
    video_preview: require("url:../media/previews/bye-bye-yesterday-preview.mp4"),
    anime_on_left: true,
    quote_anime:
      "All good things must come to an end. That's life. That's the nature of the classroom.",
    quote_character: "Korosensei",
    quote_personal:
      "The craziness of growing up, graduating, and entering adult life!",
  },
  grand_escape: {
    name: "Grand Escape",
    anime: "Weathering with You",
    date: "March 12, 2022",
    thumbnail: require("../media/thumbnails/grand-escape.png"),
    video_id: "qXhjYzM1_0g",
    theme: "self vs. society",
    blurb:
      "Our story begins with a girl who has the special ability to bring happiness to those around her. At first, everyone is excited, but soon, excitement turns into expectations, and then into crippling dependence. What happens when a superpower proves to be both a blessing and a curse?",
    video_preview: require("url:../media/previews/grand-escape-preview.mp4"),
    anime_on_left: false,
    quote_anime:
      "Everyone had their own reasons to pursue sunshine in rainy Tokyo.",
    quote_character: "Hodaka Morishima",
    quote_personal: "Is it such a crime to keep on dreaming?",
  },
  guiding_light: {
    name: "Guiding Light",
    anime: "Violet Evergarden",
    date: "December 4, 2022",
    thumbnail: require("../media/thumbnails/michishirube.png"),
    video_id: "-AezjzLjJ5w",
    theme: "inspiration",
    blurb:
      "In her first ever self-choreographed program, Diane pays tribute to all of the inspirational people and experiences that have brought her to where she is today. Diane incorporates influences from many of her previous performances to express thanks to all of the “guiding lights” in her life.",
    video_preview: require("url:../media/previews/guiding-light-preview.mp4"),
    anime_on_left: false,
    quote_anime:
      "Thank you for making my dream come true. Thank you. I feel as though I've witnessed a miracle.",
    quote_character: "Oscar Webster",
    quote_personal: "A tribute to our inspirations, our guiding lights",
  },
  silhouette: {
    name: "Silhouette",
    anime: "Naruto",
    date: "December 4, 2022",
    thumbnail: require("../media/thumbnails/silhouette.png"),
    video_id: "cjIvKqJ91G4",
    theme: "ephemeral connections",
    blurb:
      "As we embark on new journeys, people who are integral to our life today may fade into the shadows of our world tomorrow. Through a fast-paced program about the ephemeral nature of connections we have with others, Diane, Sabina, Flora, and Erina capture the surreal feeling of watching people come and go in our lives.",
    video_preview: require("url:../media/previews/silhouette-preview.mp4"),
    anime_on_left: true,
    quote_anime:
      "I always wanted to be like you. I aspired to be like you. Because of that… I was glad that you wanted to fight me.",
    quote_character: "Naruto Uzumaki",
    quote_personal: "There was so much we didn’t know yet…",
  },
  lit: {
    name: "Lit",
    anime: "A Silent Voice",
    date: "March 19, 2023",
    thumbnail: require("../media/thumbnails/lit.png"),
    video_id: "64HnV1FVTMA",
    theme: "loneliness & belonging",
    blurb:
      "A girl with emotional scars wavers between longing for human connection and the fear of getting hurt by people again. Diane, Erina, Flora, Ita, Lana, Sabina, and Sabrina hope to portray a heartfelt story about coping with loneliness, building trust, and finding where we belong.",
    video_preview: require("url:../media/previews/lit-preview.mp4"),
    anime_on_left: false,
    quote_anime:
      "Is this really ok, for me to have so much fun? But this feels kinda like... you know, like a group of friends!",
    quote_character: "Shoyo Ishida",
    quote_personal:
      "Coping with loneliness, building trust, and finding where we belong",
  },
  unbreakable_spirit: {
    name: "Unbreakable Spirit",
    anime: "Demon Slayer",
    date: "April 15, 2023",
    thumbnail: require("../media/thumbnails/tanjiro.png"),
    video_id: "PTJqg2SjsbQ",
    theme: "perseverance & resilience",
    blurb:
      "“No matter what!” Diane pushes through her Adult Masters Junior-Senior Free Skate routine with determination. Channeling Tanjiro's spirit to persevere through unexpected and relentless hardships. Diane aspires to cultivate a resilient mindset and an unbreakable spirit.",
    video_preview: require("url:../media/previews/unbreakable-spirit-preview.mp4"),
    anime_on_left: false,
    quote_anime: "No matter what, I can do it! I can fight!",
    quote_character: "Tanjiro Kamado",
    quote_personal:
      "No matter how frustrating it is, move forward, keep going. Cut off that despair.",
  },
};
