import React, { useLayoutEffect } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";

import ProgressBar from "../ProgressBar/ProgressBar";

import { ProgramSpec } from "../../../utils/programs";
import { WikiSpec } from "../../../utils/navigation";
import * as ANIMATIONS from "../../../utils/animations";

interface Props {
  program: ProgramSpec;
  wiki: WikiSpec;
  isSelected?: boolean;
}

const GalleryItem = ({
  program,
  wiki,
  isSelected,
}: Props): React.ReactElement => {
  const previewVideo: React.RefObject<HTMLVideoElement> = React.createRef();
  const quotes: { [key: string]: React.RefObject<HTMLParagraphElement> } = {
    anime: React.createRef(),
    personal: React.createRef(),
  };

  const resetQuoteAnimation = (onVideo: boolean) => {
    for (const type in quotes) {
      const quote = quotes[type].current;
      if (quote) {
        const animation = onVideo
          ? ANIMATIONS.QUOTE_ANIMATIONS.video[type]
          : ANIMATIONS.QUOTE_ANIMATIONS.image[type];
        ANIMATIONS.resetAnimation(quote, animation);
      }
    }
  };

  const restartVideo = (video: HTMLVideoElement) => {
    resetQuoteAnimation(true);
    video.currentTime = 0;
    video.play();
  };

  // Restart video and quote animation when slide is selected.
  // Pause video when slide is deselected.
  useLayoutEffect(() => {
    const video = previewVideo.current;
    video && (isSelected ? restartVideo(video) : video.pause());
  }, [isSelected]);

  // Toggle between preview video and thumbnail on click.
  const toggleVideoThumbnail = () => {
    const video = previewVideo.current;
    if (video) {
      video.pause();
      if (video.currentTime > video.duration - ANIMATIONS.IMAGE_DURATION) {
        restartVideo(video);
      } else {
        resetQuoteAnimation(false);
        video.currentTime = video.duration;
      }
    }
  };

  return (
    <div className="slide-div">
      <video
        className="preview"
        src={program.video_preview}
        muted
        ref={previewVideo}
        onClick={toggleVideoThumbnail}
      />
      <p
        className={classNames({
          quote: true,
          "quote-anime": true,
          "quote-left": program.anime_on_left,
          "quote-right": !program.anime_on_left,
        })}
        ref={quotes.anime}
      >
        “{program.quote_anime}”{" "}
        <span className="no-wrap">{"~ " + program.quote_character}</span>{" "}
        <span className="no-wrap">({program.anime})</span>
      </p>
      <p
        className={classNames({
          quote: true,
          "quote-personal": true,
          "quote-left": !program.anime_on_left,
          "quote-right": program.anime_on_left,
        })}
        ref={quotes.personal}
      >
        {wiki.enabled ? (
          <a href={wiki.path}>
            {program.quote_personal}{" "}
            <FontAwesomeIcon icon={faAngleDoubleRight} />
          </a>
        ) : (
          program.quote_personal
        )}
      </p>
      <ProgressBar isSelected={isSelected} />
    </div>
  );
};

export default GalleryItem;
