import React from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import Thumbnail from "../Thumbnail/Thumbnail";

import { ProgramSpec } from "../../utils/programs";

interface Props {
  program: ProgramSpec;
}

const WikiMetadata = ({ program }: Props): React.ReactElement => {
  const metadata: { attribute: keyof ProgramSpec; name: string }[] = [
    { attribute: "anime", name: "Anime" },
    { attribute: "song", name: "Song" },
    { attribute: "theme", name: "Theme" },
    { attribute: "skaters", name: "Skaters" },
    { attribute: "choreographers", name: "Choreographers" },
    { attribute: "date", name: "Date Performed" },
    { attribute: "location", name: "Location" },
  ];

  return (
    <div className="wiki-metadata">
      <Thumbnail program={program} />
      <h2 className="name">{program.name}</h2>
      <ol className="table">
        {metadata.map(({ attribute, name }) => (
          <li className="row" key={attribute}>
            <div className="key">
              {attribute === "skaters" &&
              program.skaters &&
              program.skaters.length === 1
                ? "Skater" // Use singular form
                : attribute === "choreographers" &&
                  program.choreographers &&
                  program.choreographers.length === 1
                ? "Choreographer" // Use singular form
                : name}
            </div>
            <div className={classnames("value", attribute)}>
              {attribute === "anime" && program.anime_link ? (
                // Anime may have link
                <a
                  href={program.anime_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {program.anime} <FontAwesomeIcon icon={faExternalLinkAlt} />
                </a>
              ) : attribute === "song" && program.song_link ? (
                // Song may have link
                <a
                  href={program.song_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {program.song} <FontAwesomeIcon icon={faExternalLinkAlt} />
                </a>
              ) : attribute === "skaters" && program.skaters ? (
                // Skaters should be listed one line each
                program.skaters.join("\n")
              ) : attribute === "choreographers" && program.choreographers ? (
                // Choreographers should be separated by commas
                program.choreographers.join(", ")
              ) : (
                program[attribute]
              )}
            </div>
          </li>
        ))}
      </ol>
    </div>
  );
};

export default WikiMetadata;
