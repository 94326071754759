export const SLIDE_DURATION = 16; // duration of one slide in carousel in seconds
export const IMAGE_DURATION = 4; // duration of image at the end of preview video in seconds

export const QUOTE_ANIMATIONS: { [key: string]: { [key: string]: string } } = {
  video: {
    anime: "fadeIn 2s ease-in 9s both",
    personal: "fadeIn 2s ease-in 11s both",
  },
  image: {
    anime: "fadeIn 0.5s",
    personal: "fadeIn 0.5s",
  },
};

export const resetAnimation = (
  element: HTMLElement,
  animation: string
): void => {
  element.style.animation = "none";
  element.offsetHeight; // trigger reflow
  element.style.animation = animation;
};
