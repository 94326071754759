import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";

import { ProgramSpec } from "../../utils/programs";

interface Props {
  program: ProgramSpec;
}

const WikiIntro = ({ program }: Props): React.ReactElement => (
  <div className="wiki-intro">
    <div className="wiki-blurb">
      <h2>
        <FontAwesomeIcon icon={faQuoteLeft} />
      </h2>
      <p>
        <br />
        <i>{program.blurb}</i>
        <br />
        <br />
      </p>
      <h2 className="blurb-quote-right">
        <FontAwesomeIcon icon={faQuoteRight} />
      </h2>
    </div>
    <div
      className="wiki-video"
      style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}
    >
      <iframe
        src={
          program.name == "Friend A"
            ? "https://drive.google.com/file/d/1ilPpriB_8V0VTJF068JzUciaKgCya0Rt/preview"
            : "https://www.youtube.com/embed/" + program.video_id
        }
        frameBorder="0"
        allowFullScreen
        style={{
          position: "absolute",
          top: "5%",
          left: "5%",
          width: "90%",
          height: "90%",
        }}
      ></iframe>
    </div>
  </div>
);

export default WikiIntro;
